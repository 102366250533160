<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <bread-crumb></bread-crumb>
        </div>
        <el-card class="el-main goodsOrder">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-input label="采购ID" v-model="pageParam.params.purchaseId" />
                <le-company-solo-select label="商户" placeholder="请选择（可输入搜索）" v-model="pageParam.params.companyId"></le-company-solo-select>
            </le-search-form>
            <le-pagview ref="repaymenDetailsPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.repaymenDetailsList">
                <el-table 
                    ref="repaymenDetailsList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :border="true"
                    :span-method='mergeCols'
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column label="采购ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.purchaseId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商户名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买数量" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.number || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备型号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceType | initDic(devTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="购买单价" min-width="120">
                        <template slot-scope="{ row }">
                            <span>&yen;{{ utils.numFormat(row.amount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="服务费（/台/年）" min-width="130">
                        <template slot-scope="{ row }">
                            <span>&yen;{{ utils.numFormat(row.serviceAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.totalAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="首付金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.downPayment) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="分期金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.creditAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="分期期数" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.totalTerm || '0' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="每期应还" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.currentAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="已还金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.recordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="剩余还款金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ utils.numFormat(row.needPayAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="还款截止日期" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.expiration || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="70" fixed="right" label="操作" class="a-flex-rcc">
                        <template slot-scope="{ row }">
                            <el-tooltip class="item" effect="dark" content="每日还款明细" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toDetails(row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <div class="a-flex-rfsc" style="margin-right: 40px">
                            <span class="a-fs-12 a-c-normal">已还款金额：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{utils.numFormat(scope.val.recordAmount)}}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span class="a-fs-12 a-c-normal">分期金额（搜索范围内总计）：</span>
                            <span class="a-fs-16 a-c-master a-fw-b">￥{{utils.numFormat(scope.val.totalAmount)}}</span>
                        </div>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>
<script>
import utils from '../../utils/util'
export default {
    data() {
        return {
            utils: utils,
            tableData: [],
            cosTableData: [],
            pageParam: null,
            devTypeDic: [],
            companyId: ''
        }
    },
    created() {
        this.companyId = Number(this.$route.query.companyId)
        this.pageParam = {
            url: this.$Config.apiUrl.getPurchaseRepaymentPlan,
            method: "post",
            params: {
                companyId: this.companyId,
                purchaseId: "",
            },
            freshCtrl: 1,
        };
        this.getDevType()
    },
    methods: {
        setTableData(data) {
            let arr = []
            // 遍历处理后台返回数据
            data.map((item,index)=>{
                item.purchaseDeviceList.map((ite,idx)=>{
                    let obj = {
                        ...ite,
                        ...item,
                        // companyId: item.companyId,
                        // companyName: item.companyName,
                        // creditAmount: item.creditAmount,
                        // currentAmount: item.currentAmount,
                        // downPayment: item.downPayment,
                        // expiration: item.expiration,
                        // needPayAmount: item.needPayAmount,
                        // payType: item.payType,
                        // purchaseId: item.purchaseId,
                        // receiveType: item.receiveType,
                        // recordAmount: item.recordAmount,
                        // termType: item.termType,
                        // totalAmount: item.totalAmount,
                        // totalTerm: item.totalTerm,
                    }
                    arr.push(obj)
                })
            })
            this.tableData = arr
            this.getCosTableData()
        },
        handlerRest() {
            this.pageParam.params = {
                companyId: this.companyId,
                purchaseId: "",
            };
            this.handlerSearch()
        },
        handlerSearch() {
            this.$refs['repaymenDetailsPage'].pageNum = 1
            this.pageParam.freshCtrl++;
        },
        toDetails (datas) {
            this.$router.push({
                path:'/finance/finance-repayment-dev-details',
                query: {
                    purchaseId: datas?datas.purchaseId:''
                }
            })
        },
        // 合并行
        mergeCols ({ row, column, rowIndex, columnIndex }) {
            if(columnIndex !== 2 && columnIndex !== 3 && columnIndex !== 4 && columnIndex !== 5){
                for (let index = 0; index < this.cosTableData.length; index++) {
                    const element = this.cosTableData[index];
                    for (let c_index = 0; c_index < element.length; c_index++) {
                        if(rowIndex == element[c_index]){
                            if(c_index == 0){
                                return {
                                    rowspan: element.length,
                                    colspan: 1
                                }
                            }else{
                                return {
                                    rowspan: 0,
                                    colspan: 0
                                }
                            }   
                        }
                    }
                }
            }
        },
        // 获取需要合并单元格的数据
        getCosTableData () {
            this.cosTableData = []
            let obj = {}
            // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
            this.tableData.map((item,index)=>{
                if(obj[item.purchaseId]){
                    obj[item.purchaseId].push(index)
                }else{
                    obj[item.purchaseId] = []
                    obj[item.purchaseId].push(index)
                }
            })
            // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
            for (const key in obj) {
                if(obj[key].length > 1)
                this.cosTableData.push(obj[key])
            }
        },
        // 获取设备型号
        getDevType () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDeviceType,
                method: "post",
                params: {
                    deviceCategroy: ''
                }
            }).then(res => {
                this.devTypeDic = res.result.data.map(item=>{
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.goodsOrder {
    padding: 8px 4px;
}
.s-btn-add{
    width: 105px;
}
.gocompany {
    color: #007AFF;
    cursor: pointer;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>